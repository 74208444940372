.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#scratchiFrame,
#merakiiFrame,#dashboardiframe, #localiframe, #partnerlocal {
  width: 1px;
  height: 1px;
  position: absolute;
  right: 0;
  top: 0;
}
.container {
  width: 884.5px;
  height: 739px;
  top: 153px;
  left: 273.5px;
  border: 1px;
  display: flex;
  justify-content: center;
  align-items: center;
}

#backgroundImg {
  width: 100%;
  height: 100%;
}

body {
  background-color: #fff8f0;
  display: flex;
  justify-content: center;
  align-items: center;
}
#login-container {
  background-color: #ffffff;
  position: absolute;
  padding: 64px;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
#ng-logo {
  width: 277.3333435058594px;
  height: 64px;
}
#learn-heading {
  font-family: Josefin Sans;
  font-size: 32px;
  font-weight: 600;
  line-height: 48px;
  letter-spacing: 0px;
  text-align: left;
}

#login-button {
  background-color: inherit;
  border: none;
}

#login-image {
  cursor: pointer;
}
#loading-image {
  margin: 2rem 2rem -2rem;
  width: 3vw;
  height: 5vh;
}

@media (max-width: 820px) {
  .container {
    width: 700.5px;
    height: 650px;
  }

  #loading-image {
    width: 3vw;
    height: 5vh;
  }

  #ng-logo {
    width: 200.3333435058594px;
    height: 40px;
  }

  #learn-heading {
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
  }

  #login-image {
    height: 50px;
  }
}

@media (max-width: 650px) {
  .container {
    width: 100%;
  }
}

@media (max-width: 500px) {
  #ng-logo {
    width: 150.3333435058594px;
    height: 40px;
  }

  #learn-heading {
    font-size: 16px;
    font-weight: 600;
    line-height: 30px;
  }

  #login-image {
    height: 30px;
  }
}

@media (max-width: 400px) {
  #ng-logo {
    width: 150.3333435058594px;
    height: 40px;
  }

  #learn-heading {
    font-size: 16px;
    font-weight: 600;
    line-height: 30px;
  }

  #login-image {
    height: 30px;
  }
  #loading-image {
    width: 6vw;
    height: 3vh;
  }

}
